
section#mobile-email-request{position:fixed;background-image:linear-gradient(180deg, #072B46 30%, #125462 90%);height:100vh;padding-top:25px
}
section#mobile-email-request img.ttv-logo{display:block;height:auto;width:16.5em;max-width:80%;margin-bottom:4.5em
}
section#mobile-email-request button{text-transform:uppercase
}
section#mobile-email-request .to-sign-in{text-align:right;font-size:14px;color:#a0a0a1
}
section#mobile-email-request .to-sign-in a{color:#222;font-weight:600;display:inline-block;margin-left:.5em
}
section#mobile-email-request .to-sign-in a:active{opacity:.7
}
section#mobile-email-request .to-sign-in{text-align:center;margin-top:1.5em
}
@media(max-width: 1100px){
section#mobile-email-request .to-sign-in{margin-top:1em
}
}
@media(max-width: 960px){
section#mobile-email-request .to-sign-in{margin-top:.7em
}
}
@media(max-width: 768px){
section#mobile-email-request .to-sign-in{margin-top:.5em
}
}