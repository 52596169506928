
section#start{display:flex;flex-flow:row nowrap;justify-content:center;align-items:center;position:absolute;top:0;bottom:0;left:0;right:0;overflow:hidden;-webkit-app-region:drag;background-image:linear-gradient(180deg, #072B46 30%, #125462 90%)
}
section#start *{-webkit-app-region:no-drag
}
section#start .hamburger-wrapper{position:absolute;z-index:3;top:0;left:0;height:40px;width:70px;display:flex;flex-flow:column nowrap;justify-content:center;align-items:center;color:#fff
}
section#start .hamburger-wrapper .hamburger{font-size:20px;cursor:default;opacity:.7;transition:opacity .3s;-webkit-app-region:no-drag
}
section#start .hamburger-wrapper .hamburger:hover{opacity:.9
}
section#start .back-wrapper{position:absolute;z-index:3;top:2.5em;left:1.5em;padding:5px;border-radius:5px;background:rgba(0,0,0,.5)
}
section#start .back-wrapper.windows-desktop{top:3.5em;left:1.2em
}
section#start .start-container{display:flex;flex-direction:row
}
@media(max-width: 768px){
section#start .start-container{height:100% !important;flex-direction:column;justify-content:flex-start
}
}
section#start .brand{display:flex;flex-direction:column;align-items:center;justify-content:center;border-radius:.5em 0 0 .5em;background-color:#dbdbdc;width:344px
}
section#start .brand img{width:230px
}
section#start .brand h1{text-align:center;padding:42px
}
@media(max-width: 768px){
section#start .brand{width:100%
}
}
section#start .right-section{background-color:#f0f0f1;width:426px;padding:50px;border-radius:0 .5em .5em 0
}
@media(max-width: 768px){
section#start .right-section{width:100%;padding:15px
}
}
section#start h1{font-size:1.4em;color:#5e5e5e
}
section#start a{color:#008dd5;cursor:pointer;text-decoration:none
}
section#start p{color:#929292
}
section#start .error-message{color:#fc5543;font-size:.9em
}
section#start .steps-breadcrumb{font-size:.9em;text-align:center
}
section#start .form{display:flex;flex-direction:column;font-size:inherit
}
section#start .form .form-item{padding-top:0;padding-bottom:1em
}
section#start .form .form-item.error input,section#start .form .form-item.error select{border-color:rgba(252,85,67,.8);outline-color:#fc5543
}
section#start .form label{color:#303444;text-transform:capitalize;display:block
}
section#start .form input,section#start .form select{width:100%;border:1px solid #cdcdcd;border-radius:5px;margin-top:.4em;font-size:1em;padding:0 .4em
}
section#start .form input::-moz-placeholder, section#start .form select::-moz-placeholder{color:#c2c2c2;font-size:.8em
}
section#start .form input:-ms-input-placeholder, section#start .form select:-ms-input-placeholder{color:#c2c2c2;font-size:.8em
}
section#start .form input::-ms-input-placeholder, section#start .form select::-ms-input-placeholder{color:#c2c2c2;font-size:.8em
}
section#start .form input::placeholder,section#start .form select::placeholder{color:#c2c2c2;font-size:.8em
}
@media(max-width: 768px){
section#start{position:fixed;min-height:100vh;overflow-x:hidden;overflow-y:auto;display:block;justify-content:flex-start;flex-flow:column nowrap
}
section#start:not(.no-extra-padding){padding:15px
}
section#start h1{font-size:1.3em
}
section#start .brand{background-color:#f5f5f5;border-radius:.5em .5em 0 0
}
section#start .brand h1{padding:0px
}
section#start .right-section{background-color:#f5f5f5;border-radius:0 0 .5em .5em
}
}
section#start .start-pages{flex:1 1 .0001px;padding:1em 3em;display:flex;flex-flow:row nowrap;justify-content:flex-start;align-items:stretch;font-size:.8em
}
section#start .start-pages .brand-section,section#start .start-pages .form-section{flex:1 1 .0001px;display:flex;flex-flow:column nowrap;justify-content:center;align-items:center
}
section#start .start-pages .brand-section img.ttv-logo{display:block;height:auto;width:16.5em;max-width:80%;margin-bottom:4.5em
}
section#start .start-pages .brand-section h1{font-size:2.3em;font-weight:600;color:#fff;padding:0 1em .6em 1em;margin:0 0 .5em 0;max-width:550px;text-align:center;position:relative
}
section#start .start-pages .brand-section h1:after{content:"";display:block;position:absolute;bottom:0;left:50%;width:40%;height:1px;background-color:rgba(255,255,255,.35);transform:translate(-50%, 0)
}
section#start .start-pages .brand-section h1.white-labelling:after{display:none
}
section#start .start-pages .brand-section img.ttv-illustration{position:relative;display:block;width:70%;max-width:656px;height:auto;left:-3%
}
section#start .start-pages .form-section{padding:2em;align-items:stretch;color:#222;font-size:14px
}
section#start .start-pages .form-section p{font-size:18px;line-height:160%;color:#222;margin:0 0 1em 0
}
section#start .start-pages .form-section .form-section-inner{border-radius:10px;background-color:#f5f5f5;border:1px solid rgba(0,0,0,.1);overflow:hidden;padding:2em 2.5em
}
@media(max-width: 1280px){
section#start .start-pages .brand-section img.ttv-logo{margin-bottom:3.5em
}
section#start .start-pages .form-section .form-section-inner{padding:1.5em 2em
}
}
@media(max-width: 1100px){
section#start .start-pages .brand-section img.ttv-logo{margin-bottom:3em
}
section#start .start-pages .brand-section h1{font-size:2em
}
section#start .start-pages .form-section{flex:1.3 1.3 .00001px;font-size:16px
}
section#start .start-pages .form-section p{font-size:16px
}
}
@media(max-width: 960px){
section#start .start-pages{padding:1em 2em
}
section#start .start-pages .brand-section img.ttv-logo{margin-bottom:2.5em
}
section#start .start-pages .brand-section h1{font-size:1.8em
}
section#start .start-pages .form-section{flex:1.8 1.8 .00001px
}
section#start .start-pages .form-section .form-section-inner{padding:1.5em
}
}
@media(max-width: 768px){
section#start .start-pages{padding:1em;display:block;flex-flow:column nowrap;justify-content:flex-start
}
section#start .start-pages .brand-section h1{font-size:2em
}
section#start .start-pages .brand-section,section#start .start-pages .form-section{flex:none
}
section#start .start-pages .form-section{padding:3em 0
}
section#start .start-pages .pt-0{padding-top:0
}
}
@media(max-width: 425px){
section#start .start-pages{padding:1em 0
}
}
section#start .signup-pages{flex:1 1 .0001px;display:flex;flex-flow:row nowrap;justify-content:center;align-items:stretch;font-size:.8em;height:100%;padding:0;position:relative;background-image:url(https://resources.telemetrytv.com/images/demo/signup-background.jpg);background-position:top center;background-size:100% auto;background-repeat:no-repeat;z-index:0
}
section#start .signup-pages:before{position:absolute;z-index:1;content:"";top:0;right:0;left:0;bottom:0;background-color:rgba(0,0,0,.75)
}
section#start .signup-pages .form-section{width:90%;max-width:550px;position:relative;padding:0;z-index:10;display:flex;flex-flow:column nowrap;justify-content:center;align-items:stretch;color:#222;font-size:14px
}
section#start .signup-pages .form-section p{font-size:18px;line-height:160%;color:#222;margin:0 0 1em 0
}
section#start .signup-pages .form-section .form-section-inner{border-radius:10px;background-color:#f5f5f5;border:1px solid rgba(0,0,0,.1);overflow:hidden
}
section#start .signup-pages .section-inner-wrapper{position:absolute;top:0;left:0;right:0;bottom:0;overflow-y:auto
}
section#start .signup-pages .section-inner-wrapper.vert-centered{display:flex;flex-flow:column nowrap;justify-content:center;align-items:stretch
}
section#start .signup-pages .section-inner-wrapper.vert-centered .form-section-inner{margin:auto 0
}
section#start .signup-pages .form-section-inner{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:stretch
}
section#start .signup-pages .form-section-inner h1{font-weight:600;text-align:center;margin:0;padding:0 0 .5em 0;font-size:1.6em;color:#161820
}
section#start .signup-pages .form-section-inner .form-section-left{flex:1 1 .0001px;padding:2em
}
@media(max-width: 768px){
section#start .signup-pages{height:100%;box-sizing:border-box;flex-flow:column nowrap;justify-content:flex-start;align-items:center;background-size:auto 100%;background-position:top left
}
section#start .signup-pages .form-section{margin-top:3em;flex:none
}
section#start .signup-pages .section-inner-wrapper{position:relative;overflow:initial;right:auto;bottom:auto
}
section#start .signup-pages .form-section-inner{margin:0
}
}
section#start.qa-src .signup-pages{background-image:url(https://qa-resources.telemetrytv.com/images/demo/signup-background.jpg)
}