
section#mobile-start{display:flex;overflow-x:hidden;overflow-y:auto;flex-flow:row nowrap;justify-content:flex-start;flex-flow:column nowrap;align-items:center;height:100%;-webkit-app-region:drag
}
section#mobile-start *{-webkit-app-region:no-drag
}
section#mobile-start input{font-size:16px
}
section#mobile-start .mobile-page-wrapper{top:80px;position:fixed;overflow-y:auto;width:100%;bottom:0
}
section#mobile-start .mobile-page-wrapper.show-footer{bottom:60px
}
section#mobile-start .mobile-start-pages .brand-section,section#mobile-start .mobile-start-pages .form-section{flex:1 1 .0001px;display:flex;flex-flow:column nowrap;justify-content:center;align-items:center
}
section#mobile-start .mobile-start-pages .form-section{padding:0 2em
}
section#mobile-start .mobile-start-pages .form-section .form-section-inner{margin-top:auto;margin-bottom:auto;overflow:hidden;padding:2em 1.5em;border-radius:10px;color:#222;background-color:#f5f5f5
}
section#mobile-start .mobile-start-pages .form-section .form-section-inner input{width:100%
}
section#mobile-start .mobile-start-pages .form-section .form-section-inner .error-message{color:#fc5543;font-size:.9em
}